import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _64ec736e = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _13bcf32d = () => interopDefault(import('../pages/announcements/index.vue' /* webpackChunkName: "pages/announcements/index" */))
const _0023f1a0 = () => interopDefault(import('../pages/approve_license/index.vue' /* webpackChunkName: "pages/approve_license/index" */))
const _67b016aa = () => interopDefault(import('../pages/bid-project/index.vue' /* webpackChunkName: "pages/bid-project/index" */))
const _633714d3 = () => interopDefault(import('../pages/career/index.vue' /* webpackChunkName: "pages/career/index" */))
const _bc50227e = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _0da274a2 = () => interopDefault(import('../pages/pdfviewer.vue' /* webpackChunkName: "pages/pdfviewer" */))
const _33562187 = () => interopDefault(import('../pages/policy/index.vue' /* webpackChunkName: "pages/policy/index" */))
const _51eb44ff = () => interopDefault(import('../pages/regulation/index.vue' /* webpackChunkName: "pages/regulation/index" */))
const _1a57dcd5 = () => interopDefault(import('../pages/announcements/_id.vue' /* webpackChunkName: "pages/announcements/_id" */))
const _6a7088dc = () => interopDefault(import('../pages/bid-project/_id.vue' /* webpackChunkName: "pages/bid-project/_id" */))
const _111a2ffb = () => interopDefault(import('../pages/career/_id.vue' /* webpackChunkName: "pages/career/_id" */))
const _e24ad820 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _64ec736e,
    name: "about"
  }, {
    path: "/announcements",
    component: _13bcf32d,
    name: "announcements"
  }, {
    path: "/approve_license",
    component: _0023f1a0,
    name: "approve_license"
  }, {
    path: "/bid-project",
    component: _67b016aa,
    name: "bid-project"
  }, {
    path: "/career",
    component: _633714d3,
    name: "career"
  }, {
    path: "/contact",
    component: _bc50227e,
    name: "contact"
  }, {
    path: "/pdfviewer",
    component: _0da274a2,
    name: "pdfviewer"
  }, {
    path: "/policy",
    component: _33562187,
    name: "policy"
  }, {
    path: "/regulation",
    component: _51eb44ff,
    name: "regulation"
  }, {
    path: "/announcements/:id",
    component: _1a57dcd5,
    name: "announcements-id"
  }, {
    path: "/bid-project/:id?",
    component: _6a7088dc,
    name: "bid-project-id"
  }, {
    path: "/career/:id",
    component: _111a2ffb,
    name: "career-id"
  }, {
    path: "/",
    component: _e24ad820,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
