
export default {
    name: "FooterComponent",
    data: () => ({
        lang: "LA",
        langes: [
            {
                val: "LA",
                text: "Laos",
                flag: "/assets/la.png",
            },
            {
                val: "TH",
                text: "Thailand",
                flag: "/assets/th.png",
            },
            {
                val: "US",
                text: "United State",
                flag: "/assets/us.png",
            },
        ],
    }),
};
