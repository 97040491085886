
import { mapGetters } from "vuex";
export default {
    name: "DesktopNav",
    data: () => ({
        // lang : this.$i18n.localeProperties.code,

        dialog: false,
        items: [
            {
                title: {
                    "en-US": "CURRENT OPENIGS",
                    "lo-LA": "ການເປີດໃນປະຈຸບັນ",
                },
                to: "/current-open",
            },
            {
                title: { "en-US": "BID PROJECT", "lo-LA": "ໂຄງການປະມູນ" },
                to: "/bid-project",
            },
        ],
    }),
    computed: {
        ...mapGetters({
            menus: "home/menus",
            loading: "home/loading",
        }),
    },
    methods: {},
};
